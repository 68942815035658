/* Asegura que la página esté centrada */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f8f8; /* Fondo gris muy claro */
  font-family: "Montserrat", sans-serif; /* Tipografía Montserrat */
  min-width: 310px;
}
.banner {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.button-placeholder {
  width: 100px;  /* Define el mismo ancho que el botón */
  height: 40px;  /* Define la misma altura que el botón */
  display: inline-block;
}
.source-logo {
  width: 50px;
  height: auto;
  margin-left: 10px; /* Opcional: espacio a la izquierda */
}

.link{
color: black;
  text-decoration: none;
}
.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 20px;
}

.info-price-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

/* Contenedor para el logo */
.logo {
  width: 260px;
  margin-top: 10px;
  margin-bottom: 20px; /* Espacio para que el logo esté un poco más cerca del input */
}

.search-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px; /* Ancho máximo para que no se vea muy ancho en pantallas grandes */
  padding: 20px;
  position: relative;
}

/* Input de búsqueda */
input {
  width: 90%;
  height: 40px;
  padding: 10px 50px 10px 15px; /* Espacio para el botón y texto dentro del input */
  font-size: 18px;
  border-radius: 20px; /* Redondeado para dar un efecto suave */
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
  outline: none;
}

.search {
  position: absolute;
  right: 0;
  height: 60%;
  padding: 10px 25px;
  border: none;
  background-color: #3fd134;
  color: white;
  cursor: pointer;
  border-radius: 0 20px 20px 0;
}

/* Icono de flecha (puedes usar una flecha HTML simple o un icono SVG) */
.search::before {
  content: "→";
  font-size: 28px;
  color: white;
}
.zocalo-mobile {
  display: none;
}

/* Hover en el botón */
.search:hover {
  background-color: #007bff;
}

.clear-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.book-row {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.book-image {
  max-width: 100px; /* Limita el tamaño de la imagen */
  margin-right: 20px; /* Separación de la imagen respecto al texto */
}

.book-info {
  flex-grow: 1; /* Toma el espacio restante entre la imagen y el precio */
  padding-right: 20px; /* Da espacio entre la información del libro y el precio */
}

.book-price {
  width: 230px;
  text-align: right;
  /* Mantiene un ancho mínimo para la columna de precios */
}

.book-price p {
  margin: 5px 0;
}

.zocalo {
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #3fd134;
  padding: 15px;
  display: flex;
  justify-content: space-evenly; /* Espacio uniforme entre los botones */
  color: white; /* Letra blanca */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  border-top: 1px solid #ddd; /* Separador visual */
}
h1{
  text-align: center;
}
.zocalo-borrar {
font-size: 18px; /* Tamaño del texto */
font-weight: bold;
cursor: pointer;
color: black; /* Letra blanca */
background-color: transparent; /* Elimina el fondo predeterminado */
border: none; /* Elimina el borde predeterminado */
padding: 10px 30px; /* Añade un padding extra para que el botón se vea mejor */
position: relative; /* Para posicionar las flechas */
outline: none; /* Elimina el borde azul del focus */

}
.zocalo-button-left,
.zocalo-button-right
{
  font-size: 18px; /* Tamaño del texto */
  font-weight: bold;
  cursor: pointer;
  color: white; /* Letra blanca */
  background-color: transparent; /* Elimina el fondo predeterminado */
  border: none; /* Elimina el borde predeterminado */
  padding: 10px 30px; /* Añade un padding extra para que el botón se vea mejor */
  position: relative; /* Para posicionar las flechas */
  outline: none; /* Elimina el borde azul del focus */
}

/* Flecha hacia la izquierda antes del texto en el botón "Anterior" */
.zocalo-button-left::before {
  content: "←"; /* Flecha hacia la izquierda */
  font-size: 22px; /* Aumenta el tamaño de la flecha */
  font-weight: bold;
  position: absolute;
  left: -10px; /* Separa más la flecha del texto */
}

/* Flecha hacia la derecha después del texto en el botón "Siguiente" */
.zocalo-button-right::after {
  content: "→"; /* Flecha hacia la derecha */
  font-size: 22px; /* Aumenta el tamaño de la flecha */
  font-weight: bold;
  position: absolute;
  right: -10px; /* Separa más la flecha del texto */
}

.zocalo-button-left:hover,
.zocalo-button-right:hover,
.zocalo-borrar:hover {
  color: #f0f0f0; /* Cambia el color al pasar el mouse */
}

.zocalo-resultados {
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.zocalo-borrar {
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* Lista de resultados */
ul {
  list-style-type: none;
  padding: 0;
  width: 95%; /* Ajusta el ancho para que no se vea apretado */
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Cada resultado en la lista */
li {
  background-color: white;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Título del libro */
h3 {
  margin: 0 0 10px 0;
}

/* Detalles del libro */
p {
  margin: 5px 0;
}

.footer {
  width: 100%;
  margin: 10px;
  bottom: 0;
  text-align: center;
}
/* Media query para pantallas con un ancho menor a 600px */
@media (max-width: 600px) {
  .zocalo {
    display: none;
  }
  .book-row {
    flex-direction: row; /* Cambia a columna en lugar de fila */
  }
  .book-image {
    margin-bottom: 30px; /* Añade espacio debajo de la imagen */
  }
  .info-price-container {
    flex-direction: column;
  }

  h1{
    font-size: 14px;
  }

  .book-info {
    width: 100%; /* La información toma el 100% del espacio disponible */
    padding-right: 0; /* Elimina el padding lateral */
    text-align: left;
    font-size: 12px;
  }

  .book-price {
    display: flex;
    flex-direction: column;
    width: 160px; /* El precio también toma el 100% del ancho */
    text-align: left;
    font-size: 10px;
  }

  /* Disminuye el tamaño de la fuente en 4px en general */

  .zocalo-mobile {
    display: flex;
    flex-direction: column;
  }
  .zocalo-button-left-mobile,
  .zocalo-button-righ-mobile,
  .zocalo-borrar {
    width: 100%; /* Botones ocupan todo el ancho */
    text-align: center; /* Alineación centrada */
    margin: 5px 0; /* Añade espacio entre botones */
  }

  input {
    font-size: 14px;
  }
  .logo {
    width: 200px;
  }
}
@media (max-width: 420px) {
  .book-row {
    flex-direction: column;
  } /* Input de búsqueda */
  input {
    width: 80%;
    height: 30px;
    padding: 5px 40px 5px 10px; /* Espacio para el botón y texto dentro del input */
    font-size: 14px;
  }

  .search {
    position: absolute;
    right: 0;
    height: 50%;
    padding: 10px 25px;
  }

  /* Icono de flecha (puedes usar una flecha HTML simple o un icono SVG) */
  .search::before {
    font-size: 20px;
  }
}
